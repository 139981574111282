import React from 'react';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { Router, Switch, Route } from "react-router-dom";
import './App.css';
import BookingConfirmation from './components/BookingConfirmationComponent';
import BookingConfirm from './components/BookingConfirmComponent';
import BookingOverview from './components/BookingOverviewComponent';
import Dashboard from './components/DashboardComponent';
import PrivateRoute from './components/PrivateRoute';
import ProfileComponent from './components/ProfileComponent';
import history from './utils/history';


export default function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <PrivateRoute path="/" exact component={Dashboard} />
          <PrivateRoute path="/booking" exact component={BookingOverview} />
          <Route path="/confirm" exact component={BookingConfirm} />
          <Route path="/confirmation" exact component={BookingConfirmation} />
          <PrivateRoute path="/profile" exact component={ProfileComponent} />
        </Switch>
      </Router>
    </div>
  );
}

