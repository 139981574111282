import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';
import DashboardTable from '../containers/dashboard/DashboardTable';
import ProductsService from '../store/products/products.service';
import Filter from './FilterComponent';
import UsersService from '../store/users/users.service';
import { useAuth0 } from '../react-auth0';
import { isEmpty } from 'lodash';

const Dashboard = () => {
    const dispatch = useDispatch();
    const [data, isDataLoading] = useSelector(state =>
        [state.products.data, state.products.loading]);
    const { user } = useAuth0();

    useEffect(() => {
        if (isEmpty(data)) {
            dispatch(ProductsService.get());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (user) {
            dispatch(UsersService.insertInternalId({
                id: user['https://rent1.com/userId'],
                sub: user.sub,
                email: user.email

            }));
        }
    }, [dispatch, user])

    const selectProduct = (product) => {
        dispatch(ProductsService.setSelected(product));
    }

    return (
        <Container fluid>
            <Filter />
            <DashboardTable
                loading={isDataLoading}
                products={data}
                onProductSelect={selectProduct}
            />
        </Container>
    )
};



export default Dashboard