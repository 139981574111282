import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';
import BookingConfirmationModal from '../containers/bookings/BookingConfirmationModal';
import BookingTable from '../containers/bookings/BookingTable';
import { useAuth0 } from '../react-auth0';
import BookingsService from '../store/bookings/bookings.service';
import ProductsService from '../store/products/products.service';
import Filter from './FilterComponent';

const BookingOverview = () => {
    const dispatch = useDispatch();
    const { getUserId } = useAuth0();
    const userId = getUserId();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const selectedProducts = useSelector(state => state.products.selectedProducts, shallowEqual);
    const [isDataLoading, success] = useSelector(state =>
        [state.bookings.loading, state.bookings.success]
    );

    const setProduct = (product, idx) => {
        debugger;
        dispatch(ProductsService.setSelected(product));
        dispatch(BookingsService.remove(idx));
    };

    const sendBookingRequest = () => {
        const req = {
            product_id: selectedProducts,
            start_date: startDate,
            end_date: endDate,
            requester_id: userId
        };

        dispatch(BookingsService.send(req));
    }

    const reset = () => {
        dispatch(BookingsService.reset());
    };


    return (
        <Container fluid>
            <Filter />
            <BookingTable
                loading={isDataLoading}
                onDelete={setProduct}
                onSendRequest={sendBookingRequest}
                products={selectedProducts}
                onSetStartDate={setStartDate}
                onSetEndDate={setEndDate}
            />

            <BookingConfirmationModal open={success} close={reset} />
        </Container>
    )
};

export default BookingOverview