import request from '../../utils/api';
import ProductsService from '../products/products.service';
import { putBookingData, removeSelectedProduct, requestBegin, requestFailure, resetBookingState, sendBookingConfirmationSuccess, sendBookingProductsRequestSuccess, sendBookingRequestSuccess, lockBooking } from './bookings.actions';


const send = (req) => {
    return (dispatch) => {
        dispatch(requestBegin());
        console.log('send');
        return request(({
            method: 'POST',
            url: "bookings",
            data: req
        })).then((res) => {
            dispatch(sendBookingRequestSuccess(res.data));
            dispatch(ProductsService.clear());
            return res.data;
        }).catch((error) => {
            dispatch(requestFailure(error))
            return error;
        })
    }
}

const getBookings = (req) => {
    return (dispatch) => {
        dispatch(requestBegin());
        console.log('getBookings');
        return request(({
            method: 'GET',
            url: `bookings/find/${req.bookingId}?u=${req.userId}`,
        })).then((res) => {
            dispatch(sendBookingRequestSuccess(res.data))
            return res.data;
        }).catch((error) => {
            dispatch(requestFailure(error))
            return error;
        })
    }
}

const getProducts = (ids) => {
    return (dispatch) => {
        if (!ids.length) {
            dispatch(requestFailure('no id provided'));
            return;
        }
        dispatch(requestBegin());
        return request(({
            method: 'GET',
            url: `products?ids=${JSON.stringify(ids)}`,
        })).then((res) => {
            dispatch(sendBookingProductsRequestSuccess(res.data));
            return res.data;
        }).catch((error) => {
            dispatch(requestFailure(error));
            return error;
        })
    }
}

const remove = (index) => dispatch => dispatch(removeSelectedProduct(index));

const reset = () => dispatch => dispatch(resetBookingState());

const updateBookingData = (idx, key, value) => dispatch => dispatch(putBookingData(idx, key, value));

const confirm = (data) => {
    return (dispatch) => {
        dispatch(requestBegin());

        return request(({
            method: 'POST',
            url: `bookings/confirm?id=${data.confirmed_by}`,
            data: data
        })).then((res) => {
            dispatch(sendBookingConfirmationSuccess());
            return res.data;
        }).catch((error) => {
            dispatch(requestFailure(error))
            return error;
        })
    }
}

const lock = (bookingId, userId) => {
    return (dispatch) => {
        dispatch(requestBegin());

        return request(({
            method: 'POST',
            url: `bookings/lock?id=${bookingId}&userId=${userId}`,
        })).then((res) => {
            dispatch(lockBooking(res.data));
        }).catch((error) => {
            dispatch(requestFailure(error))
            return error;
        })
    }
}
const BookingsService = {
    send,
    getBookings,
    getProducts,
    remove,
    reset,
    confirm,
    updateBookingData,
    lock
}

export default BookingsService;