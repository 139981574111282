import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

const FilterSelected = ({ onRemoveFilter, filters }) => {

    const labelArray = [];
    filters && filters.forEach(filter => {
        labelArray.push(
            <Label key={filter} size="large"> {filter}
                <Icon onClick={() => onRemoveFilter(filter)} name='delete' />
            </Label>);
    });

    return (
        labelArray
    )

}
export default FilterSelected;