
import { RESET_STATE, SEARCH_PRODUCTS_BEGIN, SEARCH_PRODUCTS_FAILURE, SET_SEARCH_PRODUCTS_RESULT, SET_SEARCH_PRODUCTS_VALUE } from './search.types';
const initialState = {
    results: null,
    loading: false,
    value: '',
    error: null
};

export default function searchReducer(state = initialState, action) {
    switch (action.type) {

        case SEARCH_PRODUCTS_BEGIN:
            return {
                ...state,
                loading: true
            }
        
        case SET_SEARCH_PRODUCTS_VALUE:
            return {
                ...state,
                loading: true,
                value: action.payload.value
            };

        case SET_SEARCH_PRODUCTS_RESULT:
            return {
                ...state,
                loading: false,
                results: action.payload.result
            };
        
        case SEARCH_PRODUCTS_FAILURE:
            return {
                ...state,
                results: null,
                loading: false,
                error: action.payload.error
            }
        
        case RESET_STATE:
            return initialState
        
        default:
            return state;
    }
}
