import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, List, Modal } from 'semantic-ui-react';

const BookingConfirmationModal = ({ open, close }) => {
    let [modalOpen, setModalOpen] = useState(open);

    useEffect(() => {
        setModalOpen(open)
    }, [open])

    const closeModal = () => {
        setModalOpen(!modalOpen);
        close();
    }

    return (
        <Modal
            open={modalOpen}
            onClose={closeModal}>
            <Modal.Header className="r1-btn-primary">
                Your request has been sent!</Modal.Header>
            <Modal.Content style={{ backgroundColor: '#089781' }}>
                <Modal.Description>
                    <Header style={{ color: "white" }}>What happens next:</Header>
                </Modal.Description>
                <List as='ol' style={{ color: "white" }}>
                    <List.Item>1 - Our rental partner(s) will confirm availabilty and pricing. Once confiremed, you will receive a confirmation</List.Item>
                    <List.Item>2 - Multiple partners might confirm availability. You can decide who to pick for your rental.</List.Item>
                    <List.Item>3 - To finalize the rental you must contact the partner you’ve chosen to work with.</List.Item>
                </List>
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor: '#FCC423', color: "white" }}>
                <Button onClick={closeModal} attached style={{ backgroundColor: '#FCC423' }}>
                    <Icon name='checkmark' /> Okay
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default BookingConfirmationModal;