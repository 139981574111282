import React, { useState } from 'react';
import { Button, Checkbox, Grid, Header, Icon, Menu, Modal } from 'semantic-ui-react';

const FilterModal = (props) => {
    let [modal, setModal] = useState(false);
    const handleOpen = () => setModal(true);
    const handleClose = () => setModal(false)

    return (
        <Modal
            trigger={
                <Button onClick={handleOpen} className="r1-btn-primary">
                    <Icon name='filter' /> FILTERS
                </Button>
            } open={modal} onClose={handleClose}>
            <Modal.Header>
                <Menu secondary>
                    <Menu.Item>
                        <Header as="h3">Choose Filters</Header>
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Button secondary onClick={props.onClearFilters}> Clear Selected Filters
                        </Button>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Grid columns={1} container>
                        <Grid.Column>
                            <Header as="h3">Type of equipment</Header>
                            <Grid stackable columns={3}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox
                                            label='Mini Excavator'
                                            name='equipmentGroup'
                                            value='Mini Exe'
                                            checked={props.filters.indexOf('Mini Exe') !== -1}
                                            onClick={() => props.onSetFilters('Mini Exe')}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox
                                            label='Backhoe'
                                            name='equipmentGroup'
                                            value='Backhoe'
                                            checked={props.filters.indexOf('Backhoe') !== -1}
                                            onClick={() => props.onSetFilters('Backhoe')}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox label='Bulldozer'
                                            name='equipmentGroup'
                                            value='Bulldozer'
                                            checked={props.filters.indexOf('Bulldozer') !== -1}
                                            onClick={() => props.onSetFilters('Bulldozer')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox
                                            label='Large Excavator'
                                            name='equipmentGroup'
                                            value='Large Excavator'
                                            checked={props.filters.indexOf('Large Excavator') !== -1}
                                            onClick={() => props.onSetFilters('Large Excavator')}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox
                                            label='Skid Steer'
                                            name='equipmentGroup'
                                            value='Skid Steer'
                                            checked={props.filters.indexOf('Skid Steer') !== -1}
                                            onClick={() => props.onSetFilters('Skid Steer')}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox
                                            label='United Rentals'
                                            name='equipmentGroup'
                                            value='United Rentals'
                                            checked={props.filters.indexOf('United Rentals') !== -1}
                                            onClick={() => props.onSetFilters('United Rentals')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column>
                            <Header as="h3">City</Header>
                            <Grid stackable columns={3}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox
                                            label='Richmond'
                                            name='cityGroup'
                                            value='Richmond'
                                            checked={props.filters.indexOf('Richmond') !== -1}
                                            onClick={() => props.onSetFilters('Richmond')} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox
                                            label='Vancouver'
                                            name='cityGroup'
                                            value='Vancouver'
                                            checked={props.filters.indexOf('Vancouver') !== -1}
                                            onClick={() => props.onSetFilters('Vancouver')} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox
                                            label='Burnaby'
                                            name='cityGroup'
                                            value='Burnaby'
                                            checked={props.filters.indexOf('Burnaby') !== -1}
                                            onClick={() => props.onSetFilters('Burnaby')} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox
                                            label='Surrey'
                                            name='cityGroup'
                                            value='Surrey'
                                            checked={props.filters.indexOf('Surrey') !== -1}
                                            onClick={() => props.onSetFilters('Surrey')} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox
                                            label='Langley'
                                            name='cityGroup'
                                            value='Langley'
                                            checked={props.filters.indexOf('Langley') !== -1}
                                            onClick={() => props.onSetFilters('Langley')} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox
                                            label='Whistler'
                                            name='cityGroup'
                                            value='Whistler'
                                            checked={props.filters.indexOf('Whistler') !== -1}
                                            onClick={() => props.onSetFilters('Whistler')} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions >
                <Button onClick={() => { handleClose(); props.onSave(); }} color='green' inverted> Save</Button>
                <Button onClick={() => { handleClose(); props.onCancel(); }} color='red' inverted> Cancel</Button>
            </Modal.Actions>

        </Modal>)
};


export default FilterModal;
