import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Header, Placeholder, Table, Container, Button, Confirm, Icon, Loader } from 'semantic-ui-react';
import BookingsService from '../store/bookings/bookings.service';
import MyHeader from '../containers/header/MainHeader';

const BookingConfirmation = (props) => {
    const query = queryString.parse(props.location.search);
    const dispatch = useDispatch();

    let [booking, loading, userData, locked] = useSelector(state => [
        state.bookings.data.booking,
        state.bookings.loading,
        state.bookings.data.userData,
        state.bookings.data.locked,
    ]);
    const [bookingQuery] = useState({ bookingId: query.b, userId: query.u });
    const [lockInModalStatus, setLockInModal] = useState(false);

    const openLockInModal = () => setLockInModal(true)
    const closeLockInModal = () => setLockInModal(false)

    const lockRental = (bookingId, confirmedBy) => {
        dispatch(BookingsService.lock(bookingId, confirmedBy));
    };

    useEffect(() => {
        dispatch(BookingsService.getBookings(bookingQuery));
    }, [bookingQuery, dispatch])

    return (
        (!booking && !userData)
            ? <Loader active inline='centered' />
            : <>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <MyHeader />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Container textAlign="right">
                            <Button onClick={() => window.print()}><Icon name='print' />Print</Button>
                        </Container>
                    </Grid.Row>
                </Grid>

                <Grid container columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            {!locked
                                ? <Header dividing textAlign="center" as="h1">Rental pricing and availability have been confirmed</Header>
                                : <Header dividing textAlign="center" as="h1">Rental pricing and availability is now <span style={{ color: 'green' }}>locked</span></Header>
                            }

                            <Container text as="h3" textAlign="center">{moment(booking.start_date).format('LL')} - {moment(booking.end_date).format('LL')}</Container>

                            {!locked
                                ? <Container textAlign="center">{userData.name} from {userData.user_metadata.company_name || ''} has confirmed pricing and availablity for your rental request.</Container>
                                : <Container textAlign="center">{userData.name} from {userData.user_metadata.company_name || ''} will get an email and will be in touch shortly.</Container>
                            }

                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Table >
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell>Machine name</Table.HeaderCell>
                                        <Table.HeaderCell>$ Daily</Table.HeaderCell>
                                        <Table.HeaderCell>$ Weekly</Table.HeaderCell>
                                        <Table.HeaderCell>$ Monthly</Table.HeaderCell>
                                        <Table.HeaderCell>Status</Table.HeaderCell>
                                        <Table.HeaderCell>Additional Comments</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {loading && booking ? placeHolderTable :
                                        booking.products.map((data) =>
                                            <Table.Row key={data.id} positive={data.is_available} negative={!data.is_available}>
                                                <Table.Cell>{data.product_name}</Table.Cell>
                                                <Table.Cell>$ {data.daily_pricing}</Table.Cell>
                                                <Table.Cell>$ {data.weekly_pricing}</Table.Cell>
                                                <Table.Cell>$ {data.monthly_pricing}</Table.Cell>
                                                {data.is_available && <Table.Cell positive>Available</Table.Cell>}
                                                {!data.is_available && <Table.Cell negative>Not Available</Table.Cell>}
                                                <Table.Cell width={3}>{data.comments}</Table.Cell>
                                            </Table.Row>)
                                    }
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>

                    {!locked && <Grid.Row>
                        <Grid.Column textAlign="center">
                            <Button positive onClick={() => openLockInModal()}>Lock in your rental+rate(s)</Button>
                            {userData &&
                                <Container text style={{ padding: 10 }}>Or contact {userData.user_metadata.phone || userData.email}</Container>}
                        </Grid.Column>
                    </Grid.Row>}
                </Grid>
                <Confirm
                    open={lockInModalStatus}
                    onCancel={closeLockInModal}
                    onConfirm={() => { lockRental(booking.booking_request_id, booking.confirmed_by); closeLockInModal() }}
                    confirmButton="Yes"
                    content="Are you sure you want to lock in rental+rate(s)?"
                />
            </>
    )
}


const placeHolderTable = _.times(3, (i) => (
    <Table.Row key={i}>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
    </Table.Row>
));

export default BookingConfirmation;