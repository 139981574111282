
import axios from 'axios';
//import constants from 'shared/constants';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
    baseURL: 'https://rent1-marketplace-289106.appspot.com/api'
});

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {

    const token = sessionStorage.getItem("__rent1_token__");
    if (token) {
        options.headers = {
            'Authorization': 'Bearer ' + token
        };
    }

    const onSuccess = function (response) {
        console.debug('Request Successful!', response);
        return response.data;
    }

    const onError = function (error) {
        console.error('Request Failed:', error.config);

        if (error.response) {
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);

        } else {
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    }

    return client(options)
        .then(onSuccess)
        .catch(onError);
}

export default request;