import _ from 'lodash';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Header, Icon, Input, Placeholder, Segment, Table } from 'semantic-ui-react';

const BookingTable = ({
    products,
    onDelete,
    loading,
    onSendRequest,
    onSetStartDate,
    onSetEndDate
}) => {
    return (
        !products.length && !loading ?
            <Fragment>
                <Segment placeholder>
                    <Header icon> No machines selected.</Header>
                    <ShowAllProductsBtn />
                </Segment>
            </Fragment> :
            <Table striped attached size="large" color="green" key="green">
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>Machine name</Table.HeaderCell>
                        <Table.HeaderCell>Category</Table.HeaderCell>
                        <Table.HeaderCell>City</Table.HeaderCell>
                        <Table.HeaderCell>$ Daily</Table.HeaderCell>
                        <Table.HeaderCell>$ Weekly</Table.HeaderCell>
                        <Table.HeaderCell>$ Monthly</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {products && !loading && products.map((product, idx) =>
                        <Table.Row key={product.id}>
                            <Table.Cell>
                                <Icon onClick={() => onDelete(product, idx)} name="trash alternate outline"></Icon>
                            </Table.Cell>
                            <Table.Cell key={product.id}>{product.product_name}</Table.Cell>
                            <Table.Cell>{product.product_category}</Table.Cell>
                            <Table.Cell>{product.location.city}</Table.Cell>
                            <Table.Cell>$ {product.daily_pricing}</Table.Cell>
                            <Table.Cell>$ {product.weekly_pricing}</Table.Cell>
                            <Table.Cell>$ {product.monthly_pricing}</Table.Cell>
                        </Table.Row>)
                    }
                    {loading && placeHolderTable}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={7}>
                            <Input onChange={(e) => onSetStartDate(e.target.value)} type="date" placeholder='Pick-up Date' /> -
                            <Input onChange={(e) => onSetEndDate(e.target.value)} type="date" placeholder='Drop-off Date' />
                            <Button onClick={() => onSendRequest()} secondary style={{ marginLeft: "8px" }}> Send Request </Button>
                            <ShowAllProductsBtn />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
    )
}

const ShowAllProductsBtn = () => (
    <Button
        as={Link}
        to="/"
        floated="right">
        <Icon name='arrow circle left' /> Show All Machines
    </Button>
)

const placeHolderTable = _.times(3, (i) => (
    <Table.Row key={i}>
        <Table.Cell><Checkbox disabled /></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
    </Table.Row>
))


export default BookingTable;