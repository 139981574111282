import React from 'react';
import { Loader } from 'semantic-ui-react';
import MyHeader from '../containers/header/MainHeader';
import { useAuth0 } from '../react-auth0';
import history from '../utils/history';

const Rent1Header = () => {
    const { loading, user, logout, isAuthenticated } = useAuth0();

    if (loading || !user) {
        return <Loader active inline='centered' />
    }

    const logoutHandler = () => {
        sessionStorage.clear();
        logout();
    }

    const onMyProfile = () => {
        history.push('/profile');
    }

    return (
        <MyHeader
            logoutHandler={logoutHandler}
            isAuthenticated={isAuthenticated}
            myProfileHandler={onMyProfile}
            user={user} />
    )
}

export default Rent1Header;