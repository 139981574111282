import { GET_USER_DATA_SUCCESS, REQUEST_BEGIN, REQUEST_FAILURE, RESET_USER_PROFILE, SET_USER_DATA_SUCCESS } from './users.types';

const initialState = {
    loading: false,
    error: null,
    data: null,
    success: false,
};

export default function bookingsReducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                products: []
            };

        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.user
            };
        case SET_USER_DATA_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false
            };
        case RESET_USER_PROFILE:
            return {
                ...state,
                success: true,
                error: null,
                loading: false
            }

        default:
            return state;
    }
}
