import { toastr } from 'react-redux-toastr';
import request from '../../utils/api';
import { getUserDataSuccess, requestBegin, requestFailure, resetUserProfile, setUserDataSuccess } from './users.action';
const getUser = (id) => {
    return (dispatch) => {
        dispatch(requestBegin());

        return request(({
            method: 'GET',
            url: `users/${id}`
        })).then((res) => {
            dispatch(getUserDataSuccess(res.data));
            return res.data;
        }).catch((error) => {
            dispatch(requestFailure(error));
            toastr.error('Error occured. Please try again.', error.data.message);
            return error;
        })
    }
}

const updateUser = (details) => {
    return (dispatch) => {
        dispatch(requestBegin());

        return request(({
            method: 'PUT',
            url: `users/${details.user_id}`,
            data: details
        })).then((res) => {
            dispatch(setUserDataSuccess(res.data));
            toastr.success('Profile updated', 'Your profile was updated successfully!');
            return res.data;
        }).catch((error) => {
            dispatch(requestFailure(error));
            toastr.error('Error occured. Please try again.', error.data.message);
            return error;
        })
    }
}


const insertInternalId = (details) => {
    return (dispatch) => {
        return request(({
            method: 'POST',
            url: `users`,
            data: details
        })).then((res) => {
            return res.data;
        }).catch((error) => {
            return error;
        })
    }
}


const resetPassword = (details) => {
    return (dispatch) => {
        dispatch(requestBegin());

        return request(({
            method: 'PUT',
            url: `users/${details.id}/reset`,
            data: details
        })).then((res) => {
            dispatch(setUserDataSuccess(res.data));
            toastr.success('Password updated', 'Your password was updated successfully!');
        }).catch((error) => {
            toastr.error('Error occured. Please try again.', error.data.message);
            dispatch(requestFailure(error));
        })
    }
}
const reset = () => dispatch => dispatch(resetUserProfile());

const UsersService = {
    getUser,
    updateUser,
    insertInternalId,
    resetPassword,
    reset
}

export default UsersService;