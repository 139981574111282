import { LOCK_BOOKING, GET_BOOKING_PRODUCT_REQUEST_SUCCESS, GET_BOOKING_REQUEST_SUCCESS, REMOVE_SELECTED_PRODUCT, REQUEST_BEGIN, REQUEST_FAILURE, RESET_BOOKING_STATE, SEND_BOOKING_CONFIRMATION_SUCCESS, SEND_BOOKING_REQUEST_SUCCESS, UPDATE_PRODUCT_DATA } from './bookings.actions';

const initialState = {
    loading: false,
    error: null,
    data: [],
    success: false,
    products: []
};

export default function bookingsReducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                products: []
            };

        case SEND_BOOKING_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.bookingId,
                success: true,
                products: [],
            };

        case SEND_BOOKING_CONFIRMATION_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                data: []
            }

        case GET_BOOKING_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.bookings.length ? JSON.parse(action.payload.bookings) : null,
                success: true
            };

        case GET_BOOKING_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                products: action.payload.products,
                loading: false
            };

        case REMOVE_SELECTED_PRODUCT:

            state.products.splice(action.payload.index, 1);
            return {
                ...state,
                products: state.products
            }
        case RESET_BOOKING_STATE:
            state = initialState;
            return state;

        case UPDATE_PRODUCT_DATA:
            state.data.booking.products[action.payload.idx][action.payload.key] = action.payload.value;
            return {
                ...state,
                data: state.data
            }

        case LOCK_BOOKING:
            return {
                ...state,
                loading: false,
                data: action.payload.data
            }
        default:
            return state;
    }
}
