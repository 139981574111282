export const REQUEST_BEGIN = 'REQUEST_BEGIN';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';
export const GET_BOOKING_PRODUCT_REQUEST_SUCCESS = 'SEND_BOOKING_PRODUCT_REQUEST_SUCCESS';
export const SEND_BOOKING_REQUEST_SUCCESS = 'SEND_BOOKING_REQUEST_SUCCESS';
export const SEND_BOOKING_CONFIRMATION_SUCCESS = 'SEND_BOOKING_CONFIRMATION_SUCCESS';
export const GET_BOOKING_REQUEST_SUCCESS = 'GET_BOOKING_REQUEST_SUCCESS';
export const REMOVE_SELECTED_PRODUCT = 'REMOVE_SELECTED_PRODUCT';
export const RESET_BOOKING_STATE = 'RESET_BOOKING_STATE';
export const UPDATE_PRODUCT_DATA = 'UPDATE_PRODUCT_DATA';
export const LOCK_BOOKING = 'LOCK_BOOKING';

export const resetBookingState = () => ({ type: RESET_BOOKING_STATE });
export const requestBegin = () => ({ type: REQUEST_BEGIN });
export const requestFailure = error => ({ type: REQUEST_FAILURE, payload: { error } });
export const sendBookingRequestSuccess = bookingId => ({ type: SEND_BOOKING_REQUEST_SUCCESS, payload: { bookingId } });
export const sendBookingConfirmationSuccess = data => ({ type: SEND_BOOKING_CONFIRMATION_SUCCESS, payload: { data } });
export const sendBookingProductsRequestSuccess = products => ({ type: GET_BOOKING_PRODUCT_REQUEST_SUCCESS, payload: { products } });
export const getBookingRequestSuccess = bookings => ({ type: GET_BOOKING_REQUEST_SUCCESS, payload: { bookings } });
export const removeSelectedProduct = index => ({ type: REMOVE_SELECTED_PRODUCT, payload: { index } });
export const putBookingData = (idx, key, value) => ({ type: UPDATE_PRODUCT_DATA, payload: { idx, key, value } });
export const lockBooking = data => ({ type: LOCK_BOOKING, payload: { data } });