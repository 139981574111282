import { SET_SEARCHED_PRODUCT, FETCH_PRODUCTS_BEGIN, FETCH_PRODUCTS_FAILURE, FETCH_PRODUCTS_SUCCESS, SET_SELECTED_PRODUCT } from './products.actions';

const initialState = {
    data: {},
    readOnlyData: {},
    loading: false,
    error: null,
    success: false,
    selectedProducts: null,
    productIdxMap: []
};

const getSelectedProducts = () => {
    let selectedProducts = sessionStorage.getItem("__rent1_selected_products__");
    selectedProducts = selectedProducts ? JSON.parse(selectedProducts) : [];
    return selectedProducts;
}

const setSelectedProducts = (products) => {
    products = JSON.stringify(products);
    sessionStorage.setItem("__rent1_selected_products__", products);
}

initialState.selectedProducts = getSelectedProducts();

export default function productReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PRODUCTS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_PRODUCTS_SUCCESS:
            action.payload.products.map(product => {
                state.selectedProducts.forEach(sp => {
                    product.id === sp.id ? product.checked = true : product.checked = false;
                });
                return product;
            })
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload.products,
                readOnlyData: action.payload.products
            };

        case SET_SEARCHED_PRODUCT:
            const filteredProduct = action.payload
                ? state.readOnlyData.filter(product =>
                    product.product_name === action.payload.product_name)
                : state.readOnlyData

            return {
                ...state,
                data: filteredProduct
            }

        case SET_SELECTED_PRODUCT:
            debugger;
            const selectedProductIdx = state.productIdxMap.indexOf(action.payload.id);
            if (selectedProductIdx === -1) {
                state.selectedProducts.push(action.payload);
                state.productIdxMap.push(action.payload.id);
            } else {
                state.selectedProducts.splice(selectedProductIdx, 1);
                state.productIdxMap.splice(selectedProductIdx, 1);
            }

            setSelectedProducts(state.selectedProducts);

            const data = state.data.map(product => {
                if (product.id === action.payload.id) {
                    product.checked = !product.checked;
                }
                return product;
            });

            return {
                ...state,
                productIdxMap: state.productIdxMap,
                data
            }


        case FETCH_PRODUCTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: {}
            };

        default:
            return state;
    }
}
