export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_SEARCHED_PRODUCT = 'SET_SEARCHED_PRODUCT';


export const fetchProductsBegin = () => ({ type: FETCH_PRODUCTS_BEGIN });
export const fetchProductsSuccess = products => ({ type: FETCH_PRODUCTS_SUCCESS, payload: { products } });
export const fetchProductsFailure = error => ({ type: FETCH_PRODUCTS_FAILURE, payload: { error } });
export const setSelectedProduct = product => ({ type: SET_SELECTED_PRODUCT, payload: product });
export const setSearchedProduct = product => ({ type: SET_SEARCHED_PRODUCT, payload: product });
