import _ from 'lodash';
import queryString from 'query-string';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Container, Dimmer, Grid, Header, Input, Loader, Placeholder, Segment, Table } from 'semantic-ui-react';
import BookingsService from '../store/bookings/bookings.service';
import MyHeader from '../containers/header/MainHeader';
import moment from 'moment';

const BookingConfirm = (props) => {
    const query = queryString.parse(props.location.search);
    const dispatch = useDispatch();
    const [bookingData, loading] = useSelector(state => [state.bookings.data.booking, state.bookings.loading]);
    const [bookingQuery] = useState({ bookingId: query.b, userId: query.u });
    let availability = {};

    const changeAvailability = (id, idx) => {
        availability[id] = !availability[id];
        updateBookingData(idx, "is_available", availability[id]);
    }

    const updateBookingData = (idx, key, value) => {
        dispatch(BookingsService.updateBookingData(idx, key, value));
    }

    const confirm = () => {
        let data = {
            booking_id: bookingQuery.bookingId,
            user_id: bookingQuery.userId,
            products: bookingData.products,
            confirmed_by: query.u
        }
        debugger;
        dispatch(BookingsService.confirm(data))
    };

    useEffect(() => {
        dispatch(BookingsService.getBookings(bookingQuery));
    }, [bookingQuery, dispatch])

    return (
        <Fragment>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <MyHeader />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {(!loading && bookingData) ?
                <Grid container columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as="h1">Confirm availability and price</Header>
                            <p>A partner is requesting <b>{bookingData.products.length} machine(s)</b> for potential customer nearby. Please verify pricing and availability below: </p>
                            <p><small>Rates quoted DO NOT include sub-rental discounts, which may vary. </small></p>
                            <Container text as="h3" textAlign="center">{moment(bookingData.start_date).format('LL')} - {moment(bookingData.end_date).format('LL')}</Container>

                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Table celled>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell>Machine name</Table.HeaderCell>
                                        <Table.HeaderCell>$ Daily</Table.HeaderCell>
                                        <Table.HeaderCell>$ Weekly</Table.HeaderCell>
                                        <Table.HeaderCell>$ Monthly</Table.HeaderCell>
                                        <Table.HeaderCell>Available</Table.HeaderCell>
                                        <Table.HeaderCell>Additional Comments</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {bookingData.products.map((data, idx) =>
                                        <Table.Row key={data.id}>
                                            <Table.Cell>{data.product_name}</Table.Cell>
                                            <Table.Cell><Input value={data.daily_pricing} fluid onChange={(e) => updateBookingData(idx, `daily_pricing`, e.target.value)} /></Table.Cell>
                                            <Table.Cell><Input value={data.weekly_pricing} fluid onChange={(e) => updateBookingData(idx, "weekly_pricing", e.target.value)} /></Table.Cell>
                                            <Table.Cell><Input value={data.monthly_pricing} fluid onChange={(e) => updateBookingData(idx, "monthly_pricing", e.target.value)} /></Table.Cell>
                                            <Table.Cell>
                                                <Checkbox
                                                    onChange={() => changeAvailability(data.id, idx)}
                                                    name={data.id} />
                                            </Table.Cell>
                                            <Table.Cell><Input fluid onChange={(e) => updateBookingData(idx, "comments", e.target.value)} /></Table.Cell>
                                        </Table.Row>)
                                    }
                                    {!bookingData && placeHolderTable}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column textAlign="center">
                            <Button onClick={confirm} fluid style={{ backgroundColor: "#FBAC18", color: "white" }}>Confirm</Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row textAlign="center">
                        <Grid.Column>
                            <Container text>Have questions? Call John Doe at <a href="tel:604-123-4567">604-123-4567</a> before you confirm.</Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                :
                <Fragment>
                    <Grid container columns={1}>
                        <Grid.Row>
                            <Grid.Column>
                                <Fragment>
                                    <Segment placeholder>
                                        <Container textAlign="center">
                                            {
                                                !loading ?
                                                    <>
                                                        <Header> No bookings remaining to be confirmed. </Header>
                                                        <span><a href="/"> Go Home</a></span>
                                                    </> :
                                                    <Dimmer active inverted>
                                                        <Loader />
                                                    </Dimmer>
                                            }

                                        </Container>
                                    </Segment>
                                </Fragment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Fragment>
            }
        </Fragment>
    )
}


const placeHolderTable = _.times(4, (i) => (
    <Table.Row key={i}>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
    </Table.Row>
))

export default BookingConfirm;