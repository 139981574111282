import request from '../../utils/api';
import { fetchProductsBegin, fetchProductsFailure, fetchProductsSuccess, setSelectedProduct, setSearchedProduct } from './products.actions';

const get = () => {
    let base = '/products';

    return (dispatch) => {
        dispatch(fetchProductsBegin())
        return request(({
            method: 'GET',
            url: base,
        })).then((res) => {
            dispatch(fetchProductsSuccess(res.data))
        }).catch((error) => {
            dispatch(fetchProductsFailure(error))
        })
    }
}

const setSelected = (product) => dispatch => dispatch(setSelectedProduct(product));

const setSearched = (product) => dispatch => dispatch(setSearchedProduct(product));

const ProductsService = {
    get,
    setSelected,
    setSearched
}

export default ProductsService;