import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Label, Search } from 'semantic-ui-react';
import SearchService from '../../store/search/search.service';
import ProductsService from '../../store/products/products.service';


const resultRenderer = (props) => {
    return (
        <Container>
            <Label>{props.product_name}</Label>
        </Container>
    )
};

const SearchInput = () => {
    const dispatch = useDispatch();
    let [value, setValue] = useState('');

    const [loading, results] = useSelector(state => {
        return [
            state.search.loading,
            state.search.results
        ]
    });

    const handleSearchChange = (e, query) => {
        setValue(query.value);
        if(!query.value.length) {
            dispatch(ProductsService.setSearched());
        }

        setTimeout(() => {
            if (!value.length) {
                return dispatch(SearchService.reset());

            }

            dispatch(SearchService.search(query.value))
        }, 500);
    }

    const handleResultSelect = (e, { result }) => {
        setValue(result.product_name);
        dispatch(ProductsService.setSearched(result));
    };


    return (
        <Search
            loading={loading}
            onSearchChange={debounce(handleSearchChange, 500, { leading: true })}
            results={results}
            onResultSelect={handleResultSelect}
            value={value}
            resultRenderer={resultRenderer}
        />
    )
}

export default SearchInput;