import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import FilterModal from '../containers/filter/FilterModal';
import FilterSelected from '../containers/filter/FilterSelected';

const Filter = (props) => {
    const history = useHistory();

    const existingFilters = props.appliedFilters && props.appliedFilters.length ? JSON.parse(props.appliedFilters) : [];

    const [filters, setFilters] = useState(existingFilters);

    const addFilter = (filter) => {
        const indexOfFilter = filters.indexOf(filter);

        indexOfFilter !== -1
            ? filters.splice(indexOfFilter, 1)
            : filters.push(filter);

        setFilters([...filters]);
    }

    const removeFilter = (filter) => {
        const indexOfFilter = filters.indexOf(filter);
        if (indexOfFilter !== -1) {
            filters.splice(indexOfFilter, 1)
        }

        applyFilters();
    }

    const applyFilters = () => {
        const dashboardLink = '/';
        filters.length
            ? history.push(dashboardLink + `?filters=${JSON.stringify(filters)}`)
            : history.push(dashboardLink);
    }

    return (
        <Fragment>
            <div style={{ backgroundColor: "#FBAC18", height: "70px" }}>
                <Grid style={{ paddingLeft: "2rem" }}>
                    <Grid.Column floated='left' width={10}>
                        <FilterSelected
                            filters={filters}
                            onRemoveFilter={removeFilter}
                        ></FilterSelected>
                    </Grid.Column>

                    <Grid.Column floated='right' width={2}>
                        <FilterModal
                            filters={filters}
                            onSetFilters={addFilter}
                            onClearFilters={() => setFilters([])}
                            onSave={applyFilters}
                            onCancel={() => setFilters([])}>
                        </FilterModal>
                    </Grid.Column>
                </Grid>
            </div>
        </Fragment>
    )
}

export default Filter;