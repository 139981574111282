import request from '../../utils/api';
import { resetState, searchProductsBegin, searchProductsFailure, searchProductsResult, searchProductsValue } from './search.actions';

const reset = () => {
    return (dispatch) => {
        dispatch(resetState())
    }
}

const setValue = (val) => {
    return (dispatch) => {
        dispatch(searchProductsValue(val))
        return val;
    }
}

const search = (query) => {
    return (dispatch) => {
        dispatch(searchProductsBegin())

        return request(({
            method: 'GET',
            url: `search?query=${query}`,
        })).then((res) => {
            dispatch(searchProductsResult(res.data))
            return res.data;
        }).catch((error) => {
            dispatch(searchProductsFailure(error))
            return error;
        })
    }
}


const SearchService = {
    setValue,
    reset,
    search
}

export default SearchService;