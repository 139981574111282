import _ from "lodash";
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Icon, Placeholder, Table } from 'semantic-ui-react';

const DashboardTable = ({ loading, products, onProductSelect }) => {
    return (
        <Table attached stackable size="large">
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Machine name</Table.HeaderCell>
                    <Table.HeaderCell>Category</Table.HeaderCell>
                    <Table.HeaderCell>City</Table.HeaderCell>
                    <Table.HeaderCell>$ Daily</Table.HeaderCell>
                    <Table.HeaderCell>$ Weekly</Table.HeaderCell>
                    <Table.HeaderCell>$ Monthly</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {!_.isEmpty(products) ?
                    products.map((product) =>
                        <Table.Row key={product.id} positive={product.checked}>
                            <Table.Cell>
                                <Checkbox checked={product.checked} onClick={() => onProductSelect(product)} />
                            </Table.Cell>
                            <Table.Cell key={product.id}>{product.product_name}</Table.Cell>
                            <Table.Cell>{product.product_category}</Table.Cell>
                            <Table.Cell>{product.location.city}</Table.Cell>
                            <Table.Cell>$ {product.daily_pricing}</Table.Cell>
                            <Table.Cell>$ {product.weekly_pricing}</Table.Cell>
                            <Table.Cell>$ {product.monthly_pricing}</Table.Cell>
                        </Table.Row>)
                    : loading && placeHolderTable
                }
            </Table.Body>
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan={7} >
                        <Button as={Link} to="/booking" floated="right">
                            Show Selected Machines <Icon name='arrow circle right' />
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    )
}

const placeHolderTable = _.times(3, (i) => (
    <Table.Row key={i}>
        <Table.Cell><Checkbox disabled /></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
        <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
    </Table.Row>
))

export default DashboardTable;