import React from 'react';
import { Dropdown, Grid, Icon, Image, Menu } from 'semantic-ui-react';
import SearchInput from './SearchInput';

const MyHeader = (props) => {
    return (
        <Grid>
            <Grid.Row stretched>
                <Grid.Column>
                    <Menu borderless>
                        <Menu.Item>
                            <Image
                                href="/"
                                spaced="left"
                                src="https://00e9e64bace3e5f93ab00b518884e1f6a1d4bef7007fc66206-apidata.googleusercontent.com/download/storage/v1/b/rent1-marketplace-289106.appspot.com/o/rent1-logo.svg?qk=AD5uMEvged7LPsyBz2KgXnrg0ibUQPHjswK5IbEgSEP1_S9qlyglYjWf_7vSU45RafhCcWXh4VyDDhWZfO20AIzkykYdv2Exk4QScyuw65lePEvy0_DRZEP68-ceeIZ6q55_xuwJaSSrU_KTRvDP6pqimUyBtkDzIhgLx1jkVagY1xbiPMnmzfhdfLjjNS1o5q3f85_5y5WRwce9WTNeoU2WxriDq0L2IawrexF4GS_qFo882p1t5HjPXi3KtfvHUM0J9xrSeWDQr_Wr6AAqYRVuIZwqYNvu9UzGzI2XEtoYi227hr_Py9Eye8jacrYA2sLjQP3wYyVGZMIC5q65_Q7ngvKylPPFDE8ocKFHZuu4ZWbTBoz6iFlNsZwARN0XkisdRp95iNlqWZ4P8J8uYzCR_1fMsrOWh4nwzl4sxW1oOvB7-cICXUmlMm7pT3S7MXOAUo9TOSlb-6XahTxxKiKvSmvO2eqJz9qBIdJwKn5jQvB6WMwEAiN22WROh-Pc0TNu8-WOclfjC8twK52838bVKa5ffIbsRehYBoNv1kuluM-szNeNEBKMd8gcqOUOWuOCF1FO_4ftLwruDwBjnmP1ImR4q78xYhJ8szVSReJf1BPVSwbsrLFUhk5FzmCmjg5zHhpNwtEN-SI4IT-C2Nz9k8yZng4gBmgmpmH_aTehoOnVCoRj-VnrRhmE_Iu1Mut8bndCMoIPEwpMN-Zpa1zHHeLFMdBRUQZNwBaE1uuW29X0N-g-XaEtC8dgTARimF5nurh6OX7wHTg6k05uOEoWesnuwkejXuCFuCyN-vwrFECpdolTo3be4Uyl0pMehJEuinOW53R3&isca=1" />
                        </Menu.Item>
                        {props.isAuthenticated &&
                            <><Menu.Item>
                                <SearchInput />
                            </Menu.Item>
                                <Menu.Menu position="right">
                                    <Menu.Item>
                                        <Dropdown trigger={<span><Icon name="user" />{props.user.name}</span>} item>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={props.myProfileHandler}>My Profile</Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item onClick={props.logoutHandler}>Log Out</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Menu.Item>
                                </Menu.Menu></>}
                    </Menu>
                </Grid.Column>
            </Grid.Row>
        </Grid>)
}

export default MyHeader;
