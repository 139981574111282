import { reducer as toastrReducer } from 'react-redux-toastr';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import bookings from './bookings/bookings.reducer';
import products from './products/products.reducers';
import search from './search/search.reducers';
import users from './users/users.reducers';


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
    products,
    search,
    bookings,
    users,
    toastr: toastrReducer
});

const rootReducer = (state, action) => {
    return appReducer(state, action)
}

const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk)));

export default store;