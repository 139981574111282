import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0";
import Rent1Header from './HeaderComponent';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
    const { loading, isAuthenticated, loginWithRedirect, getTokenSilently } = useAuth0();

    useEffect(() => {
        const callAPI = async () => {
            const token = await getTokenSilently();
            sessionStorage.setItem("__rent1_token__", token);
        };
        if (!loading) {
            callAPI();
        }
    }, [getTokenSilently, loading]);

    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: window.location.pathname },
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect, path, getTokenSilently]);

    const render = props =>
        isAuthenticated === true ? <Component {...props} /> : null;

    return <><Rent1Header /><Route path={path} render={render} {...rest} /></>;
};

export default PrivateRoute;