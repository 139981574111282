import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Header, Tab } from 'semantic-ui-react';
import ProfileEdit from '../containers/profile/ProfileEdit';
import { useAuth0 } from '../react-auth0';
import UsersService from '../store/users/users.service';

const ProfileComponent = () => {
    const dispatch = useDispatch();
    const [profile, profileSuccess, loading] = useSelector(state => {
        return [
            state.users.data,
            state.users.success,
            state.users.loading
        ]
    });
    const { getUserSub } = useAuth0();
    const userId = getUserSub();

    const panes = [
        {
            menuItem: 'Personal Info',
            render: () =>
                <Tab.Pane loading={loading}>
                    {profile && <ProfileEdit profile={profile} update={onUpdateProfile} success={profileSuccess} />}
                </Tab.Pane>
        },
    ]
    const onUpdateProfile = (details) => {
        dispatch(UsersService.updateUser(details));
    }

    useEffect(() => {
        if (userId) {
            dispatch(UsersService.getUser(userId));
        }
    }, [dispatch, userId])

    const resetState = () => {
        dispatch(UsersService.reset());
    }

    return (
        <Container fluid>
            <Container style={{ marginTop: "30px" }}>
                <Header as="h1" >My Profile</Header>
                <Tab
                    onTabChange={() => resetState()}
                    menu={{ secondary: true, fluid: true, vertical: true, tabular: true }}
                    panes={panes}
                />
            </Container>
        </Container>
    )
}


export default ProfileComponent