import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';

const ProfileEdit = ({ profile, update }) => {
    const [details, setDetails] = useState(profile);

    const handleChange = (evt) => {
        const value = evt.target.value;
        setDetails({
            ...details,
            [evt.target.name]: value
        });
    }
    const handleMetadata = (evt) => {
        const value = evt.target.value;
        setDetails({
            ...details,
            user_metadata: {
                [evt.target.name]: value
            }
        });
    }

    return (
        <Form loading={!profile}>
            <Form.Group widths={2}>
                <Form.Input
                    label='Name' placeholder='Name' name='name'
                    defaultValue={details.name}
                    onChange={handleChange}
                />
                <Form.Input
                    label='Phone Number' placeholder='Phone Number' name='phone'
                    defaultValue={details.user_metadata?.phone}
                    onChange={handleMetadata}
                />
            </Form.Group>
            <Form.Group widths={2}>
                <Form.Input
                    label='Email Address*' placeholder='Email Address' name='email'
                    value={details.email} />

                <Form.Input
                    label='Company Name' placeholder='Company Name' name='company_name'
                    defaultValue={details.user_metadata?.company_name} onChange={handleMetadata} />
            </Form.Group>

            <Button type='submit' primary onClick={() => update(details)}>Save</Button>
        </Form>
    )
}

export default ProfileEdit